import Joi from "joi";
import {
  configSchema,
  audioFilesSchema,
  visualFileSchema,
} from "@nhtio/malarkey-client-customization-validator";

import type {
  BrandCustomizationManifest,
  BrandAudioFiles,
  BrandVisualFiles,
} from "@nhtio/malarkey-client-customization-validator";

export interface MalarkeyModuleOptions {
  customizations: BrandCustomizationManifest;
  audioFiles: BrandAudioFiles;
  visualFiles: BrandVisualFiles;
}

export const malarkeyModuleOptionsSchema = Joi.object<MalarkeyModuleOptions>({
  customizations: configSchema.required(),
  audioFiles: audioFilesSchema.required(),
  visualFiles: visualFileSchema.required(),
});

export const runtimeConfigSchema = Joi.object({
  public: Joi.object({
    malarkey: malarkeyModuleOptionsSchema,
  }).unknown(true),
}).unknown(true);
