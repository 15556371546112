<template>
  <div>
    <ClientOnly>
      <NuxtLoadingIndicator :color="colors.primary" />
    </ClientOnly>
    <VitePwaManifest />
    <!-- @vue-ignore -->
    <NuxtLayout ref="layout" :name="name">
      <NuxtPage />
    </NuxtLayout>
    <Malarkey :updateable="updateable" />
    <LayoutHelper />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  inject,
  watch,
  nextTick,
  onMounted,
  onBeforeUnmount,
  ref,
} from "vue";
import { useRuntimeConfig } from "#app";
import { runtimeConfigSchema } from "./build-schemas";
import { useVueprint, getBootStatuses } from "@jakguru/vueprint/utilities";
import { defined } from "@nhtio/malarkey-client-core/utilities/flow";
import { getDebugger } from "@jakguru/vueprint/utilities/debug";

import type { MalarkeyModuleOptions } from "./build-schemas";
import type {
  IdentityService,
  SwalService,
  BusService,
} from "@jakguru/vueprint";
import type { PageMeta } from "#app";

const log = getDebugger("App", "#000000", "#FFFFFF");

export default defineComponent({
  name: "MalarkeyWebApp",
  setup() {
    const layout = ref();
    const { start, finish } = useLoadingIndicator();
    const { mounted, booted, ready, updateable } = useVueprint();
    const { afterEach } = useRouter();
    const runtimeConfig = useRuntimeConfig();
    const { error, value: validatedRuntimeConfig } =
      runtimeConfigSchema.validate(runtimeConfig);
    const colors = computed(() => {
      if (error) {
        return {
          background: "#EEEAE2",
          surface: "#FFFFFF",
          primary: "#3e63dd",
          secondary: "#30a46c",
          accent: "#787878",
          highlight: "#F6931E",
          notify: "#E43333",
          success: "#06972E",
          info: "#428EDA",
          warning: "#F6931E",
          error: "#E43333",
          question: "#303644",
          cancel: "#787878",
        };
      } else {
        return (validatedRuntimeConfig.public.malarkey as MalarkeyModuleOptions)
          .customizations.colors;
      }
    });
    const bus = inject<BusService>("bus");
    const identity = inject<IdentityService>("identity");
    const locallyMounted = ref(false);
    const hydrated = useMalarkeyHydration();
    const name = computed(() => {
      if (
        !booted.value ||
        !identity ||
        !locallyMounted.value ||
        !hydrated.value
      ) {
        return "default";
      }
      return identity.identified.value === true
        ? "authenticated"
        : "unauthenticated";
    });
    let awaitLayoutRefAbortController: AbortController | undefined;
    const doLayoutChange = (val?: string | undefined) => {
      if (!val) {
        val = name.value;
      }
      if (locallyMounted.value && hydrated.value) {
        const layout = computed(() => val) as PageMeta["layout"];
        setPageLayout(layout);
      } else {
        return;
      }
      nextTick(() => {
        if (awaitLayoutRefAbortController) {
          awaitLayoutRefAbortController.abort();
        }
        awaitLayoutRefAbortController = new AbortController();
        defined(layout, awaitLayoutRefAbortController.signal, [
          undefined,
          null,
        ]).then((v) => {
          if (v && v.layoutRef) {
            log("Forcing Layout Ref to Update", v.layoutRef);
            v.layoutRef.$forceUpdate();
          }
        });
      });
      nextTick(() => {
        if (bus) {
          bus.emit("window:resized", {
            local: true,
          });
        }
      });
    };
    const doEmitWindowResizeEvent = () => {
      bus.emit("window:resized", {
        local: true,
      });
    };
    let setLocallyMountedTimeout: NodeJS.Timeout | undefined;
    onMounted(() => {
      setLocallyMountedTimeout = setTimeout(() => {
        locallyMounted.value = true;
      }, 250);
      start();
      doLayoutChange();
      if (window) {
        window.addEventListener("resize", doEmitWindowResizeEvent);
        window.addEventListener("orientationchange", doEmitWindowResizeEvent);
      }
    });
    onBeforeUnmount(() => {
      if (setLocallyMountedTimeout) {
        clearTimeout(setLocallyMountedTimeout);
      }
      if (window) {
        window.removeEventListener("resize", doEmitWindowResizeEvent);
        window.removeEventListener(
          "orientationchange",
          doEmitWindowResizeEvent,
        );
      }
    });
    watch(
      () => name.value,
      (val) => {
        doLayoutChange(val);
        if ("default" === val) {
          start();
        } else {
          finish();
        }
      },
      { immediate: true },
    );
    afterEach(() => {
      doLayoutChange();
    });
    const swal = inject<SwalService>("swal");
    const onReadyOrCompleteClick = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const status: any = getBootStatuses();
      status.mounted = mounted.value;
      status.booted = booted.value;
      status.ready = ready.value;
      status.name = name.value;
      status.identityInjected = !!identity;
      status.updateable = updateable.value;
      console.log(status);
      if (swal) {
        swal.fire({
          title: "Boot Statuses",
          text: JSON.stringify(status, null, 2),
        });
      }
    };
    return {
      colors,
      name,
      onReadyOrCompleteClick,
      layout,
      updateable,
    };
  },
});
</script>
