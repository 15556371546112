import { useDisplay } from "vuetify";

const IN_BROWSER = typeof window !== "undefined";

export function useMalarkeyHydration() {
  if (!IN_BROWSER) return shallowRef(false);

  const { ssr } = useDisplay();

  if (ssr) {
    const isMounted = shallowRef(false);
    onMounted(() => {
      isMounted.value = true;
    });
    return isMounted;
  } else {
    return shallowRef(true);
  }
}
