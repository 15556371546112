import { default as indexJTfZogT4mrMeta } from "/builds/nht4944308/brands/banxso/webapp/pages/conversations/[sid]/index.vue?macro=true";
import { default as _91sid_93pqrlQRG7nfMeta } from "/builds/nht4944308/brands/banxso/webapp/pages/conversations/[sid].vue?macro=true";
import { default as index3GffpVS39NMeta } from "/builds/nht4944308/brands/banxso/webapp/pages/index.vue?macro=true";
import { default as indexv0w9vXXVQWMeta } from "/builds/nht4944308/brands/banxso/webapp/pages/login/agent/index.vue?macro=true";
import { default as agentYBB7QvawcEMeta } from "/builds/nht4944308/brands/banxso/webapp/pages/login/agent.vue?macro=true";
export default [
  {
    name: _91sid_93pqrlQRG7nfMeta?.name,
    path: "/conversations/:sid()",
    component: () => import("/builds/nht4944308/brands/banxso/webapp/pages/conversations/[sid].vue").then(m => m.default || m),
    children: [
  {
    name: "conversations-sid",
    path: "",
    component: () => import("/builds/nht4944308/brands/banxso/webapp/pages/conversations/[sid]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/nht4944308/brands/banxso/webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: agentYBB7QvawcEMeta?.name,
    path: "/login/agent",
    component: () => import("/builds/nht4944308/brands/banxso/webapp/pages/login/agent.vue").then(m => m.default || m),
    children: [
  {
    name: "login-agent",
    path: "",
    component: () => import("/builds/nht4944308/brands/banxso/webapp/pages/login/agent/index.vue").then(m => m.default || m)
  }
]
  }
]