import * as MalarkeyComponents from "@nhtio/malarkey-client-core";
import { defineNuxtPlugin, useRuntimeConfig } from "nuxt/app";
import { getDebugger } from "@jakguru/vueprint/utilities/debug";
import { runtimeConfigSchema } from "../../build-schemas";
import { getVuePrintConfigurationFromCustomizations } from "@nhtio/malarkey-client-core/utilities/configuration";
import VueMainBootstrap from "@jakguru/vueprint/plugins/main";
import VueClientBootstrap from "@jakguru/vueprint/plugins/client";
import { MalarkeySSRPlugin } from "@nhtio/malarkey-client-core/utilities/ssr";
import { MalarkeyTwilioAdapter } from "@nhtio/malarkey-client-core/twilio/malarkeyTwilioAdapter";

import type { MalarkeyModuleOptions } from "../../build-schemas";
import type { MalarkeyClientCoreOptions } from "@nhtio/malarkey-client-core";

const plugin = defineNuxtPlugin({
  name: "malarkey:client",
  async setup(nuxtApp) {
    const logger = getDebugger("malarkey:client");
    const runtimeConfig = useRuntimeConfig();
    const { error, value: validatedRuntimeConfig } =
      runtimeConfigSchema.validate(runtimeConfig);
    if (error) {
      logger(
        `Unable to load Malarkey Client Plugin due to invalid runtime config: ${error.message}`,
      );
      return;
    }
    const { malarkey } = validatedRuntimeConfig.public as {
      malarkey: MalarkeyModuleOptions;
    };
    const { main, client } = getVuePrintConfigurationFromCustomizations(
      malarkey.customizations,
      malarkey.audioFiles,
      "production" === import.meta.env.NODE_ENV ? "classic" : "module",
      "production" === import.meta.env.NODE_ENV
        ? "/worker.js"
        : "/dev-sw.js?dev-sw",
      false,
    );
    const [VuetifyComponents, VuetifyDirectives] = await Promise.all([
      import("vuetify/components"),
      import("vuetify/directives"),
    ]);
    main.vuetify!.options = {
      ...main.vuetify!.options,
      components: VuetifyComponents,
      directives: VuetifyDirectives,
    };
    nuxtApp.vueApp.use(VueMainBootstrap, main);
    nuxtApp.vueApp.use(VueClientBootstrap, client);
    nuxtApp.vueApp.use(MalarkeySSRPlugin, {
      psk: malarkey.customizations.api.psk,
    } as MalarkeyClientCoreOptions);
    nuxtApp.vueApp.use(MalarkeyComponents, {
      psk: malarkey.customizations.api.psk,
    } as MalarkeyClientCoreOptions);
    nuxtApp.vueApp.use(MalarkeyTwilioAdapter, { ssr: false });
  },
});
export default plugin;
