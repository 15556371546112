<template>
  <div>
    <VitePwaManifest />
    <!-- @vue-ignore -->
    <NuxtLayout ref="layout" name="error">
      <v-empty-state v-bind="vEmptyStateBindings" @click:action="goToHome">
        <template v-if="error.statusCode !== 404" #text>
          <p v-text="error.message" />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="error.stack" />
        </template>
      </v-empty-state>
    </NuxtLayout>
    <Malarkey :updateable="updateable" />
    <LayoutHelper />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from "vue";
import { useRuntimeConfig } from "#app";
import { runtimeConfigSchema } from "./build-schemas";
import { useVueprint } from "@jakguru/vueprint/utilities";
import { getDebugger } from "@jakguru/vueprint/utilities/debug";

import type { MalarkeyModuleOptions } from "./build-schemas";
import type { NuxtError } from "#app";

const log = getDebugger("Error", "#000000", "#FFFFFF");

export default defineComponent({
  name: "MalarkeyWebApp",
  props: {
    error: {
      type: Object as () => NuxtError,
      required: true,
    },
  },
  setup(props) {
    const err = computed(() => props.error);
    const layout = ref();
    const { updateable } = useVueprint();
    const runtimeConfig = useRuntimeConfig();
    const { error, value: validatedRuntimeConfig } =
      runtimeConfigSchema.validate(runtimeConfig);
    const images = computed(() => {
      if (error) {
        return {
          iconBlack: "",
          iconWhite: "",
          iconColor: "",
          logoColor: "",
        };
      } else {
        return (validatedRuntimeConfig.public.malarkey as MalarkeyModuleOptions)
          .visualFiles;
      }
    });
    const colors = computed(() => {
      if (error) {
        return {
          background: "#EEEAE2",
          surface: "#FFFFFF",
          primary: "#3e63dd",
          secondary: "#30a46c",
          accent: "#787878",
          highlight: "#F6931E",
          notify: "#E43333",
          success: "#06972E",
          info: "#428EDA",
          warning: "#F6931E",
          error: "#E43333",
          question: "#303644",
          cancel: "#787878",
        };
      } else {
        return (validatedRuntimeConfig.public.malarkey as MalarkeyModuleOptions)
          .customizations.colors;
      }
    });
    const goToHome = () => {
      if (window) {
        // get the base href from the head > base tag
        const baseElem = document.querySelector("base");
        if (baseElem) {
          const baseHref = baseElem.getAttribute("href");
          if (baseHref) {
            window.location.href = baseHref;
          }
        }
      }
    };
    onMounted(() => {
      if (err.value && err.value.statusCode === 404) {
        if (
          typeof window === "undefined" ||
          !window.location ||
          !window.location.href ||
          !window.location.pathname
        ) {
          log("Page not found");
        }
        if (window.location.pathname.includes("/conversations/CH")) {
          // skipping the auto redirect for conversation pages
          return;
        }
        goToHome();
      } else {
        log(err.value);
      }
    });
    const vEmptyStateBindings = computed(() => ({
      actionText: "Go to Home",
      headline:
        err.value.statusCode === 404
          ? "Page not Found"
          : `Error ${err.value.statusCode}`,
      image: images.value.iconColor,
      text:
        err.value.statusCode === 404
          ? "The page you were looking for does not exist"
          : err.value.message,
    }));
    return {
      colors,
      layout,
      updateable,
      goToHome,
      vEmptyStateBindings,
    };
  },
});
</script>

<!-- <script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps({
  error: Object as () => NuxtError,
});
</script> -->

<!-- <template>
  <div>
    <h1>{{ error.statusCode }}</h1>
    <NuxtLink to="/">Go back home</NuxtLink>
  </div>
</template> -->
