<template>
  <!-- @vue-ignore -->
  <div v-bind="layoutHelperWrapperBindings">
    <div id="layout-helper-viewport" ref="layoutHelperViewport" />
    <div id="layout-helper-safe-area" ref="layoutHelperSafeArea" />
    <div id="layout-helper-keyboard-inset" ref="layoutHelperKeyboardInset" />
    <input
      v-if="debug"
      id="layout-helper-input"
      type="text"
      style="
        background: rgba(255, 255, 255, 0.83);
        border: solid 2px #42b883;
        margin: 0 7.5px;
      "
    />
    <svg
      v-if="debug"
      id="layout-helper-svg"
      :viewBox="'0 0 ' + data.screen.width + ' ' + data.screen.height"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- Screen Representation -->
      <rect
        :x="0"
        :y="0"
        :width="data.screen.width"
        :height="data.screen.height"
        fill="transparent"
        stroke="blue"
        stroke-width="4"
      />

      <!-- Window Outer Dimensions -->
      <rect
        :x="20"
        :y="50"
        :width="data.windowOuter.width"
        :height="data.windowOuter.height"
        fill="transparent"
        stroke="green"
        stroke-width="3"
      />
      <text x="30" y="70" fill="black">
        Window Outer: {{ data.windowOuter.width }} x
        {{ data.windowOuter.height }}
      </text>

      <!-- Window Inner Dimensions -->
      <rect
        :x="40"
        :y="100"
        :width="data.windowInner.width"
        :height="data.windowInner.height"
        fill="transparent"
        stroke="orange"
        stroke-width="2"
      />
      <text x="50" y="120" fill="black">
        Window Inner: {{ data.windowInner.width }} x
        {{ data.windowInner.height }}
      </text>

      <!-- Visual Viewport Dimensions -->
      <rect
        :x="data.windowVisualViewport.pageLeft"
        :y="data.windowVisualViewport.pageTop"
        :width="data.windowVisualViewport.width"
        :height="data.windowVisualViewport.height"
        fill="transparent"
        stroke="yellow"
        stroke-width="2"
        opacity="0.8"
      />
      <text
        :x="data.windowVisualViewport.pageLeft + 5"
        :y="data.windowVisualViewport.pageTop + 20"
        fill="black"
      >
        Visual Viewport: {{ data.windowVisualViewport.width }} x
        {{ data.windowVisualViewport.height }}
      </text>

      <!-- Safe Area -->
      <!-- <rect
        :x="data.layoutHelperSafeAreaRect.x"
        :y="data.layoutHelperSafeAreaRect.y"
        :width="data.layoutHelperSafeAreaRect.width"
        :height="data.layoutHelperSafeAreaRect.height"
        fill="transparent"
        stroke="red"
        stroke-width="2"
        opacity="0.5"
      />
      <text
        :x="data.layoutHelperSafeAreaRect.x + 5"
        :y="data.layoutHelperSafeAreaRect.y + 20"
        fill="black"
      >
        Safe Area
      </text> -->

      <!-- Keyboard Inset -->
      <!-- <rect
        :x="data.layoutHelperKeyboardInsetRect.x"
        :y="data.layoutHelperKeyboardInsetRect.y"
        :width="data.layoutHelperKeyboardInsetRect.width"
        :height="data.layoutHelperKeyboardInsetRect.height"
        fill="transparent"
        stroke="purple"
        stroke-width="2"
        opacity="0.5"
      />
      <text
        :x="data.layoutHelperKeyboardInsetRect.x + 5"
        :y="data.layoutHelperKeyboardInsetRect.y + 20"
        fill="black"
      >
        Keyboard Inset
      </text> -->
    </svg>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  watch,
  inject,
} from "vue";
import dot from "dot-object";

import type { ComputedRef } from "vue";
import type { LayoutHelperData } from "../types";
import type { BusService } from "@jakguru/vueprint";

const defined = async <T = never, B = undefined>(
  what: T | B | Ref<T | B>,
  signal?: AbortSignal,
  bad: unknown[] = [undefined],
): Promise<T | B> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    if (isRef(what)) {
      while (bad.includes(what.value) && (!signal || !signal.aborted)) {
        await new Promise((r) => setTimeout(r, 100));
      }
      resolve(what.value);
    } else {
      while (bad.includes(what) && (!signal || !signal.aborted)) {
        await new Promise((r) => setTimeout(r, 100));
      }
      resolve(what);
    }
  });
};

export default defineComponent({
  name: "LayoutHelper",
  props: {
    debug: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const debug = computed(() => props.debug);
    const bus = inject<BusService>("bus");
    const windowOuter = ref<{ width: number; height: number }>({
      width: 0,
      height: 0,
    });
    const windowInner = ref<{ width: number; height: number }>({
      width: 0,
      height: 0,
    });
    const screen = ref<Omit<Screen, "orientation">>({
      availHeight: 0,
      availLeft: 0,
      availTop: 0,
      availWidth: 0,
      colorDepth: 0,
      height: 0,
      width: 0,
      pixelDepth: 0,
    });
    const screenOrientation = ref<{
      angle: number;
      type: OrientationType | "";
    }>({
      angle: 0,
      type: "",
    });
    const onWindowResize = () => {
      if (window) {
        windowOuter.value = {
          width: window.outerWidth,
          height: window.outerHeight,
        };
        windowInner.value = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
        if (window.screen) {
          screen.value = {
            availHeight: window.screen.availHeight,
            availLeft: window.screen.availLeft || 0,
            availTop: window.screen.availTop || 0,
            availWidth: window.screen.availWidth,
            colorDepth: window.screen.colorDepth,
            height: window.screen.height,
            width: window.screen.width,
            pixelDepth: window.screen.pixelDepth,
          };
          if (window.screen.orientation) {
            screenOrientation.value = {
              angle: window.screen.orientation.angle,
              type: window.screen.orientation.type,
            };
          } else {
            screenOrientation.value = {
              angle: 0,
              type: "",
            };
          }
        } else {
          screen.value = {
            availHeight: 0,
            availLeft: 0,
            availTop: 0,
            availWidth: 0,
            colorDepth: 0,
            height: 0,
            width: 0,
            pixelDepth: 0,
          };
          screenOrientation.value = {
            angle: 0,
            type: "",
          };
        }
      } else {
        windowOuter.value = {
          width: 0,
          height: 0,
        };
        windowInner.value = {
          width: 0,
          height: 0,
        };
        screen.value = {
          availHeight: 0,
          availLeft: 0,
          availTop: 0,
          availWidth: 0,
          colorDepth: 0,
          height: 0,
          width: 0,
          pixelDepth: 0,
        };
        screenOrientation.value = {
          angle: 0,
          type: "",
        };
      }
    };
    const onWindowOrientationChange = () => {
      if (window) {
        windowOuter.value = {
          width: window.outerWidth,
          height: window.outerHeight,
        };
        windowInner.value = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
        if (window.screen) {
          screen.value = {
            availHeight: window.screen.availHeight,
            availLeft: window.screen.availLeft || 0,
            availTop: window.screen.availTop || 0,
            availWidth: window.screen.availWidth,
            colorDepth: window.screen.colorDepth,
            height: window.screen.height,
            width: window.screen.width,
            pixelDepth: window.screen.pixelDepth,
          };
          if (window.screen.orientation) {
            screenOrientation.value = {
              angle: window.screen.orientation.angle,
              type: window.screen.orientation.type,
            };
          } else {
            screenOrientation.value = {
              angle: 0,
              type: "",
            };
          }
        } else {
          screen.value = {
            availHeight: 0,
            availLeft: 0,
            availTop: 0,
            availWidth: 0,
            colorDepth: 0,
            height: 0,
            width: 0,
            pixelDepth: 0,
          };
          screenOrientation.value = {
            angle: 0,
            type: "",
          };
        }
      } else {
        windowOuter.value = {
          width: 0,
          height: 0,
        };
        windowInner.value = {
          width: 0,
          height: 0,
        };
        screen.value = {
          availHeight: 0,
          availLeft: 0,
          availTop: 0,
          availWidth: 0,
          colorDepth: 0,
          height: 0,
          width: 0,
          pixelDepth: 0,
        };
        screenOrientation.value = {
          angle: 0,
          type: "",
        };
      }
    };
    const windowVisualViewport = ref<
      Omit<
        VisualViewport,
        | "onresize"
        | "onscroll"
        | "addEventListener"
        | "removeEventListener"
        | "dispatchEvent"
      >
    >({
      height: 0,
      offsetLeft: 0,
      offsetTop: 0,
      pageLeft: 0,
      pageTop: 0,
      scale: 0,
      width: 0,
    });
    const onVisualViewportResize = () => {
      if (window && window.visualViewport) {
        windowVisualViewport.value = {
          height: window.visualViewport.height,
          offsetLeft: window.visualViewport.offsetLeft,
          offsetTop: window.visualViewport.offsetTop,
          pageLeft: window.visualViewport.pageLeft,
          pageTop: window.visualViewport.pageTop,
          scale: window.visualViewport.scale,
          width: window.visualViewport.width,
        };
      } else {
        windowVisualViewport.value = {
          height: 0,
          offsetLeft: 0,
          offsetTop: 0,
          pageLeft: 0,
          pageTop: 0,
          scale: 0,
          width: 0,
        };
      }
    };
    const onVisualViewportScroll = () => {
      if (window && window.visualViewport) {
        windowVisualViewport.value = {
          height: window.visualViewport.height,
          offsetLeft: window.visualViewport.offsetLeft,
          offsetTop: window.visualViewport.offsetTop,
          pageLeft: window.visualViewport.pageLeft,
          pageTop: window.visualViewport.pageTop,
          scale: window.visualViewport.scale,
          width: window.visualViewport.width,
        };
      } else {
        windowVisualViewport.value = {
          height: 0,
          offsetLeft: 0,
          offsetTop: 0,
          pageLeft: 0,
          pageTop: 0,
          scale: 0,
          width: 0,
        };
      }
    };
    const layoutHelperViewport = ref<HTMLElement | null>(null);
    const layoutHelperSafeArea = ref<HTMLElement | null>(null);
    const layoutHelperKeyboardInset = ref<HTMLElement | null>(null);
    let layoutHelperViewportResizeObserver: ResizeObserver | undefined;
    let layoutHelperSafeAreaResizeObserver: ResizeObserver | undefined;
    let layoutHelperKeyboardInsetResizeObserver: ResizeObserver | undefined;

    const layoutHelperViewportRect = ref<Omit<DOMRect, "toJSON">>({
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
      x: 0,
      y: 0,
    });
    const layoutHelperSafeAreaRect = ref<Omit<DOMRect, "toJSON">>({
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
      x: 0,
      y: 0,
    });
    const layoutHelperKeyboardInsetRect = ref<Omit<DOMRect, "toJSON">>({
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
      x: 0,
      y: 0,
    });

    const onLayoutHelperViewportResize = (r: HTMLElement) => {
      if (!r) {
        return;
      }
      const rect = r.getBoundingClientRect();
      layoutHelperViewportRect.value = {
        bottom: rect.bottom,
        height: rect.height,
        left: rect.left,
        right: rect.right,
        top: rect.top,
        width: rect.width,
        x: rect.x,
        y: rect.y,
      };
    };
    const onLayoutHelperSafeAreaResize = (r: HTMLElement) => {
      if (!r) {
        return;
      }
      const rect = r.getBoundingClientRect();
      layoutHelperSafeAreaRect.value = {
        bottom: rect.bottom,
        height: rect.height,
        left: rect.left,
        right: rect.right,
        top: rect.top,
        width: rect.width,
        x: rect.x,
        y: rect.y,
      };
    };
    const onLayoutHelperKeyboardInsetResize = (r: HTMLElement) => {
      if (!r) {
        return;
      }
      const rect = r.getBoundingClientRect();
      layoutHelperKeyboardInsetRect.value = {
        bottom: rect.bottom,
        height: rect.height,
        left: rect.left,
        right: rect.right,
        top: rect.top,
        width: rect.width,
        x: rect.x,
        y: rect.y,
      };
    };

    let doLayoutHelperViewportResizeAbortController:
      | AbortController
      | undefined;
    const doLayoutHelperViewportResize = async () => {
      if (doLayoutHelperViewportResizeAbortController) {
        doLayoutHelperViewportResizeAbortController.abort();
      }
      doLayoutHelperViewportResizeAbortController = new AbortController();
      const r = await defined(
        layoutHelperViewport,
        doLayoutHelperViewportResizeAbortController.signal,
        [null, undefined],
      );
      if (!r) {
        return;
      }
      onLayoutHelperViewportResize(r);
    };
    let doLayoutHelperSafeAreaResizeAbortController:
      | AbortController
      | undefined;
    const doLayoutHelperSafeAreaResize = async () => {
      if (doLayoutHelperSafeAreaResizeAbortController) {
        doLayoutHelperSafeAreaResizeAbortController.abort();
      }
      doLayoutHelperSafeAreaResizeAbortController = new AbortController();
      const r = await defined(
        layoutHelperSafeArea,
        doLayoutHelperSafeAreaResizeAbortController.signal,
        [null, undefined],
      );
      if (!r) {
        return;
      }
      onLayoutHelperSafeAreaResize(r);
    };
    let doLayoutHelperKeyboardInsetResizeAbortController:
      | AbortController
      | undefined;
    const doLayoutHelperKeyboardInsetResize = async () => {
      if (doLayoutHelperKeyboardInsetResizeAbortController) {
        doLayoutHelperKeyboardInsetResizeAbortController.abort();
      }
      doLayoutHelperKeyboardInsetResizeAbortController = new AbortController();
      const r = await defined(
        layoutHelperKeyboardInset,
        doLayoutHelperKeyboardInsetResizeAbortController.signal,
        [null, undefined],
      );
      if (!r) {
        return;
      }
      onLayoutHelperKeyboardInsetResize(r);
    };

    const onVirtualKeyboardGeometryChange = () => {
      doLayoutHelperViewportResize();
      doLayoutHelperSafeAreaResize();
      doLayoutHelperKeyboardInsetResize();
    };

    const vApplicationElem = ref<HTMLElement | null>(null);
    const vApplicationRect = ref<Omit<DOMRect, "toJSON">>({
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
      x: 0,
      y: 0,
    });

    const updateVApplicationRect = () => {
      if (window && document) {
        if (!vApplicationElem.value) {
          vApplicationElem.value = document.querySelector(".v-application");
        }
      }
      if (vApplicationElem.value) {
        const rect = vApplicationElem.value.getBoundingClientRect();
        vApplicationRect.value = {
          bottom: rect.bottom,
          height: rect.height,
          left: rect.left,
          right: rect.right,
          top: rect.top,
          width: rect.width,
          x: rect.x,
          y: rect.y,
        };
      }
    };

    const handleUnspecifiedInteraction = () => {
      onWindowResize();
      onWindowOrientationChange();
      onVisualViewportResize();
      onVisualViewportScroll();
      onVirtualKeyboardGeometryChange();
      updateVApplicationRect();
    };

    let mountedAbortController: AbortController | undefined;
    let focusable: NodeListOf<Element> | undefined;
    onMounted(() => {
      if (window) {
        window.addEventListener("resize", onWindowResize);
        window.addEventListener("orientationchange", onWindowOrientationChange);
        // unspecific interactions
        window.addEventListener("click", handleUnspecifiedInteraction);
        window.addEventListener("blur", handleUnspecifiedInteraction);
        window.addEventListener("focus", handleUnspecifiedInteraction);
        window.addEventListener("pointermove", handleUnspecifiedInteraction);
        window.addEventListener("scroll", handleUnspecifiedInteraction);
        window.addEventListener("touchend", handleUnspecifiedInteraction);
        window.addEventListener("touchmove", handleUnspecifiedInteraction);
        window.addEventListener(
          "visibilitychange",
          handleUnspecifiedInteraction,
        );
        if (window.visualViewport) {
          window.visualViewport.addEventListener(
            "resize",
            onVisualViewportResize,
          );
          window.visualViewport.addEventListener(
            "scroll",
            onVisualViewportScroll,
          );
        }
        if (window.navigator && "virtualKeyboard" in window.navigator) {
          // @ts-expect-error virtualKeyboard is not in the types
          window.navigator.virtualKeyboard.addEventListener(
            "geometrychange",
            onVirtualKeyboardGeometryChange,
          );
        }
        if (document) {
          focusable = document.querySelectorAll(
            "input, textarea, select, button, a, [tabindex]",
          );
          focusable.forEach((f) => {
            f.addEventListener("focus", handleUnspecifiedInteraction);
            f.addEventListener("blur", handleUnspecifiedInteraction);
          });
        }
        handleUnspecifiedInteraction();
      }
      mountedAbortController = new AbortController();
      defined(layoutHelperViewport, mountedAbortController.signal, [null]).then(
        (r) => {
          if (!r) {
            return;
          }
          const bound = onLayoutHelperViewportResize.bind(null, r);
          layoutHelperViewportResizeObserver = new ResizeObserver(bound);
          layoutHelperViewportResizeObserver.observe(r);
          bound();
        },
      );
      defined(layoutHelperSafeArea, mountedAbortController.signal, [null]).then(
        (r) => {
          if (!r) {
            return;
          }
          const bound = onLayoutHelperSafeAreaResize.bind(null, r);
          layoutHelperSafeAreaResizeObserver = new ResizeObserver(bound);
          layoutHelperSafeAreaResizeObserver.observe(r);
          bound();
        },
      );
      defined(layoutHelperKeyboardInset, mountedAbortController.signal, [
        null,
      ]).then((r) => {
        if (!r) {
          return;
        }
        const bound = onLayoutHelperKeyboardInsetResize.bind(null, r);
        layoutHelperKeyboardInsetResizeObserver = new ResizeObserver(bound);
        layoutHelperKeyboardInsetResizeObserver.observe(r);
        bound();
      });
    });
    onBeforeUnmount(() => {
      if (window) {
        window.removeEventListener("resize", onWindowResize);
        window.removeEventListener(
          "orientationchange",
          onWindowOrientationChange,
        );
        window.removeEventListener("click", handleUnspecifiedInteraction);
        window.removeEventListener("blur", handleUnspecifiedInteraction);
        window.removeEventListener("focus", handleUnspecifiedInteraction);
        window.removeEventListener("pointermove", handleUnspecifiedInteraction);
        window.removeEventListener("scroll", handleUnspecifiedInteraction);
        window.removeEventListener("touchend", handleUnspecifiedInteraction);
        window.removeEventListener("touchmove", handleUnspecifiedInteraction);
        window.removeEventListener(
          "visibilitychange",
          handleUnspecifiedInteraction,
        );
        if (window.visualViewport) {
          window.visualViewport.removeEventListener(
            "resize",
            onVisualViewportResize,
          );
          window.visualViewport.removeEventListener(
            "scroll",
            onVisualViewportScroll,
          );
        }
        if (window.navigator && "virtualKeyboard" in window.navigator) {
          // @ts-expect-error virtualKeyboard is not in the types
          window.navigator.virtualKeyboard.removeEventListener(
            "geometrychange",
            onVirtualKeyboardGeometryChange,
          );
        }
      }
      if (mountedAbortController) {
        mountedAbortController.abort();
        mountedAbortController = undefined;
      }
      if (layoutHelperViewportResizeObserver) {
        layoutHelperViewportResizeObserver.disconnect();
      }
      if (layoutHelperSafeAreaResizeObserver) {
        layoutHelperSafeAreaResizeObserver.disconnect();
      }
      if (layoutHelperKeyboardInsetResizeObserver) {
        layoutHelperKeyboardInsetResizeObserver.disconnect();
      }
      if (focusable) {
        focusable.forEach((f) => {
          f.removeEventListener("focus", handleUnspecifiedInteraction);
          f.removeEventListener("blur", handleUnspecifiedInteraction);
        });
      }
    });
    const layoutHelperWrapperBindings = computed(() => ({
      style: {
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        visibility: debug.value ? "visible" : "hidden",
        zIndex: 20000,
        backgroundColor: "#35495e",
        color: "#42b883",
        overflowY: "auto",
      },
    }));
    const data: ComputedRef<LayoutHelperData> = computed(
      () =>
        ({
          windowOuter: windowOuter.value,
          windowInner: windowInner.value,
          screen: screen.value,
          screenOrientation: screenOrientation.value,
          windowVisualViewport: windowVisualViewport.value,
          layoutHelperViewportRect: layoutHelperViewportRect.value,
          layoutHelperSafeAreaRect: layoutHelperSafeAreaRect.value,
          layoutHelperKeyboardInsetRect: layoutHelperKeyboardInsetRect.value,
          vApplicationRect: vApplicationRect.value,
        }) as LayoutHelperData,
    );
    let doOnDataChangeTimeout: NodeJS.Timeout | undefined;
    const onDataChangeCallback = () => {
      if (bus) {
        bus.emit("layout:updated", { local: true }, data.value);
      }
      if (
        document &&
        document.documentElement &&
        document.documentElement.style
      ) {
        const rootStyle = document.documentElement.style;
        const asDot = dot.dot(data.value);
        const asCssVars = Object.assign(
          {},
          ...Object.keys(asDot).map((k) => {
            const v = asDot[k];
            let key = k.replace(/\./g, "-"); // Replace dots with hyphens
            key = key.replace(/([A-Z])/g, "-$1").toLowerCase(); // Insert a hyphen before each uppercase letter and convert to lower case
            key = `--lh-${key}`; // Prefix with --lh-
            return { [key]: v };
          }),
        );
        Object.entries(asCssVars).forEach(([key, value]) => {
          rootStyle.setProperty(key, `${value}px` as string);
        });
      }
    };
    const onDataChange = (is: LayoutHelperData, was: LayoutHelperData) => {
      const isString = JSON.stringify(is);
      const wasString = JSON.stringify(was);
      if (isString === wasString) {
        return;
      }
      if (doOnDataChangeTimeout) {
        clearTimeout(doOnDataChangeTimeout);
      }
      doOnDataChangeTimeout = setTimeout(onDataChangeCallback, 100);
    };
    // @ts-expect-error not sure why ts doesn't like this, but it doesn't
    watch(data, onDataChange, {
      deep: true,
      immediate: true,
    });
    return {
      layoutHelperViewport,
      layoutHelperSafeArea,
      layoutHelperKeyboardInset,
      layoutHelperWrapperBindings,
      data,
    };
  },
});
</script>

<style>
#layout-helper-viewport {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
}

#layout-helper-safe-area {
  position: fixed;
  top: env(safe-area-inset-top, 0);
  right: env(safe-area-inset-right, 0);
  bottom: env(safe-area-inset-bottom, 0);
  left: env(safe-area-inset-left, 0);
  visibility: hidden;
}

#layout-helper-keyboard-inset {
  position: fixed;
  top: env(keyboard-inset-top, 0);
  bottom: env(keyboard-inset-bottom, 0);
  left: env(keyboard-inset-left, 0);
  right: env(keyboard-inset-right, 0);
  visibility: hidden;
}

#layout-helper-svg {
  margin: 0 80px;
  margin-top: 80px;
}
</style>
